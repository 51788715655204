@media (max-width: 1000px) {
  .cards-filters-jobs {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .line-cards-candidate-container {
    gap: 30px;
  }
}

.title-row-card-candidate {
  color: #32325d;
  font-family: Open Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px;
}
