@media (max-width: 1000px) {
  .cards-filters-jobs {
    display: none !important;
  }
}

@media (max-width: 1000px) {
  .details-jobs-card {
    margin-top: 100px;
  }
}

@media (max-width: 630px) {
  .details-jobs-card {
    display: none !important;
  }
}

.container-group-cardjobs {
  flex: 1
}

@media (max-width: 1000px) {
  .container-group-cardjobs {
    margin-top: 100px !important;
  }
}