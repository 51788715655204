.card-list-jobs {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
}

.content-data-list-jobs {
  color: black;
  font-size: 14px;
  font-weight: 100;
  display: flex;
  justify-content: flex-end;
  padding-top: 7px;
  padding-right: 23px;
}

.content-data-list-jobs-button {
  display: none;
  justify-content: space-evenly;
  margin-bottom: 12px;
}

.content-data-list-jobs-button {
  display: none;
  justify-content: space-evenly;
  margin-bottom: 12px;
}

@media (max-width: 630px) {
  .content-data-list-jobs-button {
    display: flex;
  }
}

.container-card-list-jobs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 110px 20px 20px;
}

.content-card-list-jobs {
  color: black;
  font-weight: bold;
  text-align: justify;
  font-size: 19px;
}

.content-name-company {
  color: black;
  font-size: 15px;
  font-weight: 600;
}

.cotent-info-local-jobs {
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  color: black;
  font-size: 15px;
  font-weight: 100;
}

.cotent-desc-local-jobs {
  color: black;
  font-size: 15px;
  font-weight: 100;
  text-align: justify;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 321px;
}

@media (max-width: 630px) {
  .cotent-desc-local-jobs {
    width: 218px;
  }
}

@media (min-width: 1500px) {
  .cotent-desc-local-jobs {
    width: 100%;
  }
}

.card-list-jobs:focus {
  border: 1px solid #4db7a2 !important;
}

@media (max-width: 1000px) {
  .logo-job {
    display: none !important;
  }
}
