.card-profile-image-modal {
  position: relative;
}
.card-profile-image-modal img {
  max-width: 180px;
  border-radius: 0.375rem;
}

.content-modal-name-job {
  color: black;
  font-size: 15px;
  font-weight: 200;
}

.content-modal-name-job-details {
  color: black;
  font-weight: bold;
  text-align: justify;
  font-size: 20px;
}
