.card-list-jobs-detalis {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  /* height: 1000px;
  overflow: auto; */
}

.content-data-list-jobs-detalis {
  color: black;
  font-size: 14px;
  font-weight: 100;
  display: flex;
  justify-content: flex-end;
  padding-top: 7px;
  padding-right: 23px;
}

.container-card-list-jobs-detalis {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  max-height: 550px;
}

.content-card-list-jobs-detalis {
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.content-name-company-detalis {
  color: #4db7a2;
  font-size: 15px;
  font-weight: 600;
}

.cotent-info-local-jobs-detalis {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  color: black;
  font-size: 15px;
  font-weight: 100;
}

.cotent-desc-local-jobs-detalis {
  color: black;
  font-size: 15px;
  font-weight: 100;
  text-align: justify;
  -webkit-line-clamp: 33;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-width: 380px;
  height: 400px;
  overflow-y: auto;
}

@media (min-width: 1500px) {
  .cotent-desc-local-jobs-detalis {
    max-width: 100%;
  }
}

.button-custom-apply-job,
.button-custom-apply-job:hover,
.button-custom-apply-job:focus,
.button-custom-apply-job:active {
  background-color: #4db7a2 !important;
  color: #fff !important;
  border-radius: 20px !important;
  width: 124px !important;
  align-items: center !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  gap: 10px
}

.filter-job-details-about {
  display: flex;
  gap: 30px;
  font-size: 15px;
  font-weight: 100;
}

.line-filter-job-details-about {
  width: 100%;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-top: 2px solid rgba(0, 0, 0, 0.1) !important;
}

.filter-item-job-details-about {
  color: black;
}

.filter-item-job-details-about:hover {
  cursor: pointer;
}

.filter-item-job-details-about:focus,
.filter-item-job-details-about:first-child {
  color: #4db7a2 !important;
  font-weight: bold;
  border-bottom: 1px solid #4db7a2;
}