.bg-translucent-pharmacy {
    background: linear-gradient(87deg, #004c4e 0, #004c4e40 100%) !important;
  }

  .bg-translucent-pharmacy-recruiter {
    background: linear-gradient(87deg, #4DB7A2 0, #4db7a2a3 100%) !important;
  }

  @media (max-width: 575px) {
    .bg-translucent-pharmacy-recruiter {
      margin-bottom: 110px !important;
    }
  }

  .button-custom,
  .button-custom:hover,
  .button-custom:active,
  .button-custom:focus {
    border-color: #640764 !important;
    color: #FFF !important;
    background-color: #640764 !important;
  }

  .button-custom-recruiter,
  .button-custom-recruiter:hover,
  .button-custom-recruiter:active,
  .button-custom-recruiter:focus {
    border-color: #004C4E !important;
    color: #FFF !important;
    background-color: #004C4E !important;
  }