.card-profile-image-modal {
  position: relative;
}
.card-profile-image-modal img {
  max-width: 180px;
  border-radius: 0.375rem;
}

.content-modal-name-state
.content-name-name
.content-name-years {
  font-weight: bold;
  color: black;
  font-size: 15px;
  font-weight: 200;
  text-align: center;
}

.content-modal-celphone
.content-modal-name-job {
  color: black !important;
  font-size: 15px !important;
  font-weight: 200 !important;
  text-align: center !important;
}

.content-modal-name-job-details {
  color: black;
  font-weight: bold;
  text-align: justify;
  font-size: 20px;
}

.social-network-button:hover{
  cursor: pointer;
  text-align: center;
}

.whatsapp-text,
.linkedin-url-box {
  max-width: 100%; 
  word-wrap: break-word; 
  text-align: center; 
  margin-top: 5px; 
}

@media print {
  .hide-on-pdf {
    display: none !important;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

.content-name-name {
  width: 100%; 
  overflow: hidden;
  white-space: nowrap; 
}

.break-word {
  word-break: break-word;
  max-width: 100%; /* Defina a largura máxima conforme necessário */
}
