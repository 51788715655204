.drop-fiter-style {
    border: 1px solid #DDD;
    border-radius: 20px;
    color: red !important;
    font-style: red !important;
    
}

.drop-fiter-style:hover {
    color: black !important;
}
.drop-fiter-style-icon {
    margin-left: 5px;
    transform: rotate(270deg);
}