.card-candidate-container {
  padding-top: 20px;
  width: 100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  min-height: 232px;
  display: flex;
  flex-direction: column;
}

.card-candidate-separetor {
  display: flex;
  flex: 1;
}

.card-candidate-content {
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
}

.card-candidate-content-2 {
  flex: 1.5;
}

.title-locale-city-candidate {
  color: #616161;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.title-details-candidate-obs {
  color: #616161;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  width: 190px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.title-experience-candidate-card {
  color: #4db7a2;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-top: 15px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.card-name-age-candidate-bottom {
  background-color: #4db7a2;
  color: #fff;
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 6px;
  justify-content: space-around;
}

.card-name-age-candidate-bottom:hover {
  cursor: pointer;
}

.content-name-age-candidate-bottom {
  color: #fff;
  font-family: Open Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.25px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 200px;
}

.card-candidate-icons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.card-profile-image-candidate img {
  width: 100px;
  border-radius: 0.375rem;
  height: 100px;
}

.card-profile-image-recruiter img {
  width: 180px;
  border-radius: 0.375rem;
  height: 180px;
}

.heart-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.reject-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: -8px;
}

.favorite-card .favorite-indicator {
  position: absolute;
  top: 5%; 
  right: -20px;
  background-color: #4db7a2;
  color: white;
  padding: 5px 20px; 
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
  font-size: 12px; 
  font-weight: bold; 
}

.rejected-card .rejected-indicator {
  position: absolute;
  top: 12%; 
  right: -15px;
  background-color: #f44336;
  color: white;
  padding: 5px 20px; 
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
  font-size: 12px; 
  font-weight: bold; 
}

.favorite-card .card-candidate-separetor {
  position: relative;
  z-index: 0;
}